<template>
  <v-container fluid>
    <v-card v-if="estudio.datos" style="position: relative;">
      <v-tabs
        v-model="tab"
        class="elevation-4"
        :style="
          `position: sticky; z-index: 100; top: ${
            $route.path == '/detalles_estudio' ? '64px' : '0'
          }`
        "
      >
        <v-tab>Gestión</v-tab>
        <v-tab v-if="$root.acceso('EDITAR_ESTUDIOS')">Contactos</v-tab>
        <v-tab>Datos</v-tab>
        <v-tab>Geolocalización</v-tab>
        <v-tab>Documentacion</v-tab>
        <v-spacer></v-spacer>
        <v-card-actions class="pa-2">
          <v-btn color="secondary" rounded @click.stop="$router.go(-1)">
            <v-icon left>mdi-arrow-left-thick</v-icon>
            Volver
          </v-btn>
          <v-btn
            color="success"
            rounded
            @click.stop="cambiarEstado = true"
            v-if="
              $root.acceso('EDITAR_ESTUDIOS') && estudio.estado == 'Eliminado'
            "
          >
            <v-icon left>mdi-restore</v-icon>
            Restaurar estudio
          </v-btn>
          <v-btn
            color="warning"
            :outlined="Boolean(estudio.fav)"
            rounded
            @click.stop="toggleFav"
          >
            <v-icon left>mdi-star</v-icon>
            {{ estudio.fav ? "Eliminar de" : "Añadir a " }} Favoritos
          </v-btn>
          <v-btn
            color="error"
            rounded
            @click.stop="eliminarEstudio"
            v-if="
              $root.acceso('EDITAR_ESTUDIOS') && estudio.estado != 'Eliminado'
            "
          >
            <v-icon left>mdi-delete</v-icon>
            Eliminar estudio
          </v-btn>
          <v-btn
            color="primary"
            rounded
            @click="getEstudio"
            v-if="$root.acceso('EDITAR_ESTUDIOS')"
          >
            <v-icon left>mdi-refresh</v-icon>
            Recargar
          </v-btn>
          <v-btn
            color="primary"
            rounded
            :to="`/enviar?idEstudio=${estudio.idEstudio}`"
          >
            <v-icon left>mdi-pencil</v-icon>EDITAR
          </v-btn>
        </v-card-actions>
      </v-tabs>

      <v-tabs-items class="pa-2" v-model="tab">
        <v-tab-item>
          <v-row>
            <v-col cols="12" md="3">
              <div v-if="$root.acceso('EDITAR_ESTUDIOS')">
                <v-dialog v-model="cambiarEstado" max-width="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      block
                      x-large
                      :color="getSituacionIcon(estudio.estado).color"
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      class="d-flex pa-4 text-center align-center justify-center text-button"
                    >
                      <v-icon left>{{
                        getSituacionIcon(estudio.estado).icon
                      }}</v-icon>
                      {{ estudio.estado }}
                      <small
                        v-if="
                          (
                            estudio.cronograma.find((e) => e.interno == '0') ||
                            {}
                          ).fechaCambio
                        "
                        >~
                        {{
                          timeAgo(
                            (
                              estudio.cronograma.find(
                                (e) => e.interno == "0"
                              ) || {}
                            ).fechaCambio
                          )
                        }}</small
                      >
                    </v-btn>
                  </template>
                  <cambiar-estado
                    @reload="getEstudio"
                    @close="cambiarEstado = false"
                    :estudio="estudio.idEstudio"
                  />
                </v-dialog>
                <v-dialog v-model="cambiarEstadoInt" max-width="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      block
                      x-large
                      :color="getSituacionIcon(estudio.estadoInterno).color"
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      class="d-flex pa-4 text-center align-center justify-center text-button mt-6"
                    >
                      <v-icon left>{{
                        getSituacionIcon(estudio.estadoInterno).icon
                      }}</v-icon>
                      {{ estudio.estadoInterno }}
                      <small
                        v-if="
                          (
                            estudio.cronograma.find((e) => e.interno == '1') ||
                            {}
                          ).fechaCambio
                        "
                        >~
                        {{
                          timeAgo(
                            (
                              estudio.cronograma.find(
                                (e) => e.interno == "1"
                              ) || {}
                            ).fechaCambio
                          )
                        }}</small
                      >
                    </v-btn>
                  </template>
                  <cambiar-estado
                    @reload="getEstudio"
                    @close="cambiarEstadoInt = false"
                    :estudio="estudio.idEstudio"
                    interno
                  />
                </v-dialog>
              </div>
              <div v-else>
                <v-btn
                  block
                  x-large
                  :color="getSituacionIcon(estudio.estado).color"
                  outlined
                  class="d-flex pa-4 text-center align-center justify-center primary--text text-button"
                  style="pointer-events: none;"
                >
                  <v-icon left>{{
                    getSituacionIcon(estudio.estado).icon
                  }}</v-icon>
                  {{ estudio.estado }}
                </v-btn>
              </div>

              <v-divider class="mt-4" />
              <aux-input title="Estudio">
                {{ estudio.idEstudio }}
              </aux-input>
              <v-divider />
              <aux-input title="Nombre proyecto">
                {{ estudio.datos.nombreProyecto }}
              </aux-input>
              <v-divider />
              <aux-input title="Tipo estudio">
                <div class="d-flex">
                  {{ estudio.tipoEstudio }}
                  <v-btn
                    v-if="$root.acceso('EDITAR_ESTUDIOS')"
                    @click.stop="cambioTipoEstudio = true"
                    class="ml-auto"
                    small
                    color="primary"
                    icon
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </div>
              </aux-input>
              <v-divider />
              <template v-if="$root.acceso('EDITAR_ESTUDIOS')">
              <aux-input
                title="Comercial"
              >
                <div class="d-flex">
                  {{
                    [estudio.comercial, estudio.subComercial]
                      .filter((x) => x)
                      .join(" | ")
                  }}
                  <v-btn
                    @click.stop="cambioComercialEstudio = true"
                    class="ml-auto"
                    small
                    color="primary"
                    icon
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </div>
              </aux-input>
              <v-divider class="mb-1" />
              <v-divider />
                <aux-input title="Potencia estudio">
                    <div class="d-flex">
                      {{estudio.datos.potenciaEstudio}}
                        <v-btn :to="`/enviar?idEstudio=${estudio.idEstudio}`" class="ml-auto" small icon color="primary" >
                          <v-icon small>mdi-pencil</v-icon>
                        </v-btn>
                    </div>
								</aux-input>
								<v-divider></v-divider>
								<aux-input title="Precio Pico">
                    <div class="d-flex">
                      {{estudio.datos.precioPico}}
                        <v-btn :to="`/enviar?idEstudio=${estudio.idEstudio}`" class="ml-auto" small icon color="primary" >
                          <v-icon small>mdi-pencil</v-icon>
                        </v-btn>
                    </div>
								</aux-input>
								<v-divider></v-divider>
								<aux-input title="Precio Total">
                    <div class="d-flex">
                      {{estudio.datos.precioTotal}}
                        <v-btn :to="`/enviar?idEstudio=${estudio.idEstudio}`" class="ml-auto" small icon color="primary" >
                          <v-icon small>mdi-pencil</v-icon>
                        </v-btn>
                    </div>
								</aux-input>
								<v-divider></v-divider>
              </template>
            </v-col>
            <v-col cols="12" md="9">
              <tarjeta-datos header icon="playlist-plus">
                <template v-slot:titulo>
                  Mensajes estudio
                  <v-btn
                    class="ml-auto"
                    small
                    color="primary"
                    @click.stop="getEstudio"
                  >
                    <v-icon left>mdi-refresh</v-icon>Recargar
                  </v-btn>
                </template>

                <Chat
                  :messages="estudio.mensajes"
                  :idEstudio="estudio.idEstudio"
                  @reload="getEstudio"
                />
              </tarjeta-datos>
            </v-col>

            <v-col v-if="$root.acceso('EDITAR_ESTUDIOS')" cols="12" md="6">
              <div class="d-flex align-center" style="gap: 10px">
                <v-subheader>Cronograma</v-subheader>
                <v-chip-group
                  v-model="filterNotas"
                  active-class="primary--text"
                  column
                >
                  <v-chip label value="General">General</v-chip>
                  <v-chip label value="Interno">Interno</v-chip>
                  <v-chip label value="Notas">Notas</v-chip>
                  <v-chip label value="Contactos">Contactos</v-chip>
                </v-chip-group>
                <v-btn @click="descargarCronograma" small color="primary" text>
                  <v-icon left>mdi-download</v-icon>
                  Descargar .CSV
                </v-btn>
              </div>

              <cronograma-estudio
                :key="filterNotas"
                :idEstudio="estudio.idEstudio"
                :filter-notas="filterNotas"
                :cronograma="cronograma.notas"
                @reload="getEstudio"
              />
            </v-col>

            <v-col v-if="$root.acceso('EDITAR_ESTUDIOS')" cols="12" md="6">
              <v-subheader>Alertas</v-subheader>
              <div class="d-flex mt-n6 align-center">
                <v-spacer></v-spacer>
                <v-alert type="info" text dense class="ma-0"
                  >{{
                    estudio.cronograma.filter(
                      (n) => n.tipo == "Alerta" && n.enviado == 0
                    ).length
                  }}
                  alerta(s) activas</v-alert
                >

                <v-dialog max-width="700" v-model="addAlertDialog">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      text
                      small
                      class="ml-2"
                      @click.stop="addAlertDialog = true"
                      v-on="on"
                      v-bind="attrs"
                    >
                      <v-icon left>mdi-bell-plus-outline</v-icon>
                      Añadir alerta
                    </v-btn>
                  </template>

                  <CrearAlerta
                    :key="addAlertDialog"
                    :idEstudio="estudio.idEstudio"
                    @reload="getEstudio"
                    @close="addAlertDialog = false"
                  />
                </v-dialog>
              </div>

              <cronograma-estudio
                :idEstudio="estudio.idEstudio"
                :cronograma="cronograma.alertas"
                @reload="getEstudio"
                no-button
              />
            </v-col>

            <v-col
              v-if="
                $root.acceso('EDITAR_ESTUDIOS') ||
                  ['Completado', 'Estudio Listo'].includes(estudio.estado)
              "
              cols="12"
              md="6"
            >
              <tarjeta-datos
                titulo="Documentación final"
                icon="file-document-edit-outline"
              >
                <DocumentacionEstudio :idEstudio="estudio.idEstudio" final />
              </tarjeta-datos>
            </v-col>

            <v-col v-if="$root.acceso('EDITAR_ESTUDIOS')" cols="12" md="6">
              <tarjeta-datos header titulo="Autoestudios" icon="cog">
                <div class="pa-2">
                  <div class="d-flex justify-space-between">
                    <v-btn
                      v-if="estudio.autoestudio"
                      @click.stop="descargarAutoestudio"
                      color="primary"
                      outlined
                      large
                    >
                      <v-icon left>mdi-download</v-icon>DESCARGAR AUTOESTUDIO
                    </v-btn>
                    <v-btn
                      :disabled="autoestudioMissing.length > 0"
                      @click="realizarAutoestudio"
                      color="success"
                      outlined
                      large
                      :loading="creatingAutoestudio"
                    >
                      <v-icon left>mdi-auto-fix</v-icon>REALIZAR AUTOESTUDIO
                    </v-btn>
                  </div>
                  <div class="d-flex mt-2" style="gap: 6px">
                    <v-alert
                      v-for="(missing, index) in autoestudioMissing"
                      :key="index"
                      dense
                      class="ma-0"
                      type="error"
                      text
                      >Falta {{ missing.nombre }}</v-alert
                    >
                  </div>
                </div>
              </tarjeta-datos>
            </v-col>
          </v-row>
          <v-overlay v-if="loading" absolute :opacity="0.6">
            <v-progress-circular
              size="100"
              width="6"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-overlay>
        </v-tab-item>
        <v-tab-item v-if="$root.acceso('EDITAR_ESTUDIOS')">
          <div
            v-if="estudio.emailComercial || estudio.telefonoComercial"
            class="d-flex flex-wrap ma-2"
            style="gap: 6px"
          >
            <v-chip outlined>
              <v-icon left>mdi-phone</v-icon>
              {{ estudio.telefonoComercial }}
            </v-chip>
            <v-chip outlined>
              <v-icon left>mdi-email</v-icon>
              {{ estudio.emailComercial }}
            </v-chip>
          </div>
          <v-subheader v-else>Sin datos de contacto</v-subheader>
          <v-divider class="my-2" />
          <HistoricoLlamadas :idEstudio="estudio.idEstudio" />
        </v-tab-item>
        <v-tab-item>
          <v-row>
            <v-col cols="12" md="4">
              <tarjeta-datos
                header
                titulo="Información Proyecto"
                icon="format-list-bulleted"
                :datos="[
                  {
                    nombre: 'Nombre proyecto',
                    valor: estudio.datos.nombreProyecto,
                  },
                  {
                    nombre: 'Descripcion proyecto',
                    valor: estudio.datos.descripcionProyecto,
                  },
                  { nombre: 'Tipo estudio', valor: estudio.tipoEstudio },
                  {
                    nombre: 'Formato estudio',
                    valor: estudio.datos.formatoEstudio,
                  },
                  { nombre: 'Latitud', valor: estudio.datos.latitud },
                  { nombre: 'Longitud', valor: estudio.datos.longitud },
                  {
                    nombre: 'Nº Cubiertas',
                    valor: estudio.datos.cubiertas.length || 1,
                  },
                  { nombre: 'Inclinación', valor: estudio.datos.inclinacion },
                  { nombre: 'Azimut', valor: estudio.datos.azimut },
                  {
                    nombre: 'Uralita',
                    valor: estudio.datos.uralita ? 'Sí' : 'No',
                  },
                ]"
              ></tarjeta-datos>
            </v-col>
            <v-col cols="12" md="4">
              <tarjeta-datos
                header
                titulo="Información Suministro"
                icon="gauge"
                :datos="[
                  { nombre: 'CUPS', valor: estudio.datos.suministro.cups },
                  ...(
                    estudio.datos.suministro.cupsExtras || []
                  ).map((c, i) => ({ nombre: 'CUPS ' + (i + 2), valor: c })),
                  {
                    nombre: 'Dirección',
                    valor: estudio.datos.suministro.direccion,
                  },
                ]"
              ></tarjeta-datos>
              <tarjeta-datos
                class="mt-6"
                header
                titulo="Información Cliente"
                icon="account"
                :datos="[
                  { nombre: 'Nombre', valor: estudio.datos.cliente.nombre },
                  {
                    nombre: 'Identificador',
                    valor: estudio.datos.cliente.identificador,
                  },
                  {
                    nombre: 'Nombre Representante',
                    valor: estudio.datos.cliente.representanteNombre,
                  },
                  {
                    nombre: 'Identificador',
                    valor: estudio.datos.cliente.representanteDNI,
                  },
                  ...estudio.datos.cliente.contactos.map((c, i) => ({
                    nombre: i == 0 ? 'Contactos' : '',
                    valor: c,
                  })),
                ]"
              >
                <template v-slot:titulo>
                  <div class="d-flex align-center" style="width: 100%">
                    <h5>Información Cliente</h5>
                    <v-spacer />
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-avatar
                          v-on="on"
                          v-bind="attrs"
                          v-if="estudio.clienteAlumbra && $root.acceso('DEV')"
                          size="16"
                        >
                          <v-img src="/img/fav_alumbraenergia.png"></v-img>
                        </v-avatar>
                      </template>
                      Es cliente de Alumbra
                    </v-tooltip>
                  </div>
                </template>
              </tarjeta-datos>
            </v-col>
            <v-col cols="12" md="4">
              <tarjeta-datos
                header
                titulo="Información Instalación"
                icon="lightning-bolt"
                :datos="[
                  { nombre: 'Tarifa', valor: estudio.datos.suministro.tarifa },
                ]"
              >
                <v-divider />
                <v-simple-table>
                  <thead>
                    <tr>
                      <th>Tarifa Periodo</th>
                      <th>Potencia Contratada</th>
                      <th>Precio Potencia</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="[key, value] in Object.entries(
                        estudio.datos.suministro.periodos
                      )"
                      :key="key"
                    >
                      <td>{{ key }}</td>
                      <td>{{ value.potencia }}</td>
                      <td>{{ value.precio }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </tarjeta-datos>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item>
          <v-row>
            <v-col cols="12" md="8">
              <tarjeta-datos header titulo="Mapa" icon="map">
                <div style="min-height: 500px; display: grid">
                  <Map
                    noEdit
                    :center="{
                      lat: Number(estudio.datos.latitud),
                      lng: Number(estudio.datos.longitud),
                    }"
                    :zoom="18"
                    v-model="estudio.datos.cubiertas"
                    :marker="estudio.datos.freezePosition"
                    ref="map"
                  />
                </div>
              </tarjeta-datos>
            </v-col>
            <v-col cols="12" md="4">
              <tarjeta-datos
                header
                icon="google-maps"
                :datos="[
                  { nombre: 'Latitud', valor: estudio.datos.latitud },
                  { nombre: 'Longitud', valor: estudio.datos.longitud },
                ]"
              >
              <template v-slot:titulo>
                <div>Coordenadas</div>
                <v-spacer></v-spacer>
                <v-btn
                    small
                    icon
                    color="primary"
                    @click="
                      copyTextWrapper(
                        [estudio.datos.latitud, estudio.datos.longitud].join(', ')
                      )
                    "
                  >
                    <v-icon small>mdi-content-copy</v-icon>
                  </v-btn>
              </template>
                <v-divider />
                <v-subheader>Cubiertas seleccionadas</v-subheader>
                <div class="d-flex flex-column ma-4" style="gap: 16px">
                  <v-card
                    width="100%"
                    v-for="c in cubiertasMarcadas"
                    :key="c.id"
                    class="py-3"
                    @click.stop="$refs.map.panTo(c.center)"
                  >
                    <v-row no-gutters>
                      <v-col
                        cols="auto"
                        class="d-flex justify-center align-center"
                      >
                        <v-avatar
                          :color="c.color"
                          size="30"
                          class="mx-2"
                        />
                      </v-col>
                      <v-col class="d-flex flex-column mx-2">
                        <h5>{{ c.nombre }}</h5>
                        <h6>{{ c.area }}</h6>
                      </v-col>
                      <v-col cols="auto" class="d-flex align-center">
                        <v-icon small left>
                          mdi-google-maps
                        </v-icon>
                      </v-col>
                      <v-col class="d-flex flex-column mr-2">
                        <span><b>Lat</b>: {{ c.center.lat }}</span>
                        <span><b>Lng</b>: {{ c.center.lng }}</span>
                      </v-col>
                      <v-col class="d-flex justify-end align-center">
                        <v-btn
                          icon 
                          small
                          color="primary"
                          class="mr-1"
                          @click="
                            copyTextWrapper(
                              [c.center.lat, c.center.lng].join(', ')
                            )
                          "
                        >
                          <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </div>
              </tarjeta-datos>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item>
          <v-row no-gutters class="d-flex align-center mx-4 mt-4">
            <v-col cols="12" sm>
              <DocumentacionEstudio :idEstudio="estudio.idEstudio" />
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <v-dialog v-model="cambioTipoEstudio" max-width="700">
      <CambiarTipo
        @close="cambioTipoEstudio = false"
        @reload="getEstudio"
        :estudio="estudio.idEstudio"
      />
    </v-dialog>

    <v-dialog v-model="cambioComercialEstudio" max-width="700">
      <CambiarComercial
        @close="cambioComercialEstudio = false"
        @reload="getEstudio"
        :estudio="estudio.idEstudio"
      />
    </v-dialog>

    <ConfirmDialog ref="confirm" />
  </v-container>
</template>

<script>
import {
  downloadFile,
  parseDate,
  getSituacionIcon,
  timeAgo,
  jsonToCsv,
  copyText,
} from "@/utils/index.js";
import { req } from "@/utils/validations.js";

export default {
  components: {
    TarjetaDatos: () => import("@/components/TarjetaDatos.vue"),
    StatusChip: () => import("@/components/StatusChip.vue"),
    CambiarEstado: () => import("@/components/CambiarEstado.vue"),
    AuxInput: () => import("@/components/AuxInput.vue"),
    Map: () => import("@/components/Map.vue"),

    MensajeTimeline: () => import("@/components/MensajeTimeline.vue"),
    NuevoMensajeTimeline: () => import("@/components/NuevoMensajeTimeline.vue"),

    DragAndDrop: () => import("@/components/DragAndDrop.vue"),
    CronogramaEstudio: () => import("@/components/CronogramaEstudio.vue"),
    CrearAlerta: () => import("@/components/CrearAlerta.vue"),

    DocumentacionEstudio: () => import("@/components/DocumentacionEstudio.vue"),

    HistoricoLlamadas: () =>
      import("@/components/llamadas/HistoricoLlamadas.vue"),

    Chat: () => import("@/components/Chat/Chat.vue"),
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),

    CambiarTipo: () => import("@/components/CambiarTipo.vue"),
    CambiarComercial: () => import("@/components/CambiarComercial.vue"),
  },
  props: {
    _idEstudio: String | Number,
  },
  data() {
    return {
      tab: 0,
      estudio: {},
      estudioFreeze: {},
      idEstudio: this._idEstudio || this.$route.query.idEstudio,
      addAlertDialog: false,
      cambioTipoEstudio: false,
      cambioComercialEstudio: false,

      loading: false,
      estadosInternos: [],
      cambiarEstado: false,
      cambiarEstadoInt: false,
      creatingAutoestudio: false,

      newMessage: {
        show: false,
        files: [],
        msg: null,
      },

      filterNotas: null,

      documentoFinal: null,

      nuevoEstado: {},
    };
  },
  computed: {
    cronograma() {
      return {
        notas: this.estudio.cronograma.filter((x) => x.tipo != "Alerta"),
        alertas: this.estudio.cronograma.filter((x) => x.tipo == "Alerta"),
      };
    },
    cubiertasMarcadas() {
      if (
        this.estudio.datos.cubiertas == null ||
        this.estudio.datos.cubiertas.length == 0
      )
        return [];
      const cubiertasMarcadas = this.estudio.datos.cubiertas.filter(
        (c) => c.polygon.visible
      );
      if (cubiertasMarcadas.length > 0 && !this.estudio.freezePosition) {
        const coords = cubiertasMarcadas[0].polygon
          .getPath()
          .getArray()
          .map((p) => [p.lat(), p.lng()]);

        var x = coords.map((xy) => xy[0]);
        var y = coords.map((xy) => xy[1]);
        var cx = (Math.min(...x) + Math.max(...x)) / 2;
        var cy = (Math.min(...y) + Math.max(...y)) / 2;
        this.estudio.latitud = cx;
        this.estudio.longitud = cy;
      }
      return cubiertasMarcadas;
    },
    autoestudioMissing() {
      return [
        { nombre: "CUPS", value: this.estudio.datos.suministro.cups },
        { nombre: "Inclinación", value: this.estudio.datos.inclinacion },
        { nombre: "Azimut", value: this.estudio.datos.azimut },
        { nombre: "Latitud", value: this.estudio.datos.latitud },
        { nombre: "Longitud", value: this.estudio.datos.longitud },
      ].filter((x) => x.value == null);
    },
  },
  mounted() {
    if (this.idEstudio == null) return;
    this.getEstudio();
    this.getEstadosInternos();
  },
  methods: {
    getSituacionIcon,
    parseDate,
    timeAgo,
    copyTextWrapper(text) {
      copyText(text)
        .then(() => this.$root.$emit("snack", "Copiado al portapapeles"))
        .catch(() =>
          this.$root.$emit("snack", "No se ha podido copiar al portapapeles")
        );
    },
    async getEstudio() {
      this.loading = true;
      const { data: estudio } = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/estudios.php`,
        params: {
          idEstudio: this.idEstudio,
        },
      });

      this.estudio = {
        ...estudio,
        datos: {
          suministro: {},
          cliente: {},
          ...JSON.parse(estudio.datos),
        },
      };

      const makeInmutable = (target) => new Proxy(target, { set() { return } })

      this.estudioFreeze = makeInmutable( {
          datos: {
            suministro: {},
            cliente: {},
            ...JSON.parse(estudio.datos),
          }
        } )

      await this.readMessages();

      this.loading = false;
    },
    async readMessages() {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_API_URL}/leerMensajes.php`,
        data: {
          idsMensajes: this.estudio.mensajes
            .filter((m) => !Number(m.leido))
            .map((m) => m.idMensaje),
        },
      });

      this.$root.$emit("reloadMensajesSinLeer");
    },
    async getEstadosInternos() {
      const { data: estados } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URL}/opciones/estados.php`,
        params: {
          interno: 1,
        },
      });
      this.estadosInternos = estados;
    },
    async descargarCronograma() {
      const csvHeaders = [
        { key: "usuario" },
        { key: "apagado" },
        { key: "enviado" },
        { key: "fecha", dataType: "date" },
        { key: "resumen" },
        { key: "texto" },
        { key: "tipo" },
      ];

      jsonToCsv(
        this.cronograma.notas,
        csvHeaders,
        `cronograma_estudio_${this.estudio.idEstudio}_${Date.now()}.csv`
      );
    },
    async realizarAutoestudio() {
      this.creatingAutoestudio = true;
      const {data} = await axios({
        method: "POST",
        // url: `${process.env.VUE_APP_API_URL}/autoestudio.php`,
        url: `https://renovables.alumbraenergia.es/api/public/autoestudio.php`,
        data: {
          estudios: this.estudio.idEstudio,
        },
        responseType: 'blob'
      });

      this.creatingAutoestudio = false;

      downloadFile(
        new File(
          [data],
          `DATOS_ESTUDIO_${this.estudio.datos.nombreProyecto}.xlsx`,
          {
            type: "application/octet-stream",
          }
        )
      );

      this.getEstudio();

      // this.modalDatos = true;
    },
    async descargarAutoestudio() {
      const { data } = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/autoestudio.php`,
        params: { idEstudio: this.estudio.idEstudio },
        responseType: "blob",
      });

      downloadFile(
        new File(
          [data],
          `AUTOESTUDIO_${this.estudio.datos.nombreProyecto}_${Date.now()}.xlsx`,
          {
            type: "application/excel",
          }
        )
      );
    },
    async eliminarEstudio() {
      // if (!confirm("¿Estás seguro de que quieres eliminar este estudio?"))
      //   return;

      const motivo = await this.$refs.confirm.open(
        "Eliminar estudio",
        "¿Estás seguro de que quieres eliminar este estudio?",
        { confirmColor: "error", width: 600, addExplanation: true }
      );
      if (!motivo) return;

      axios({
        method: "DELETE",
        url: `${process.env.VUE_APP_API_URL}/crearEstudio.php`,
        data: {
          idEstudio: this.idEstudio,
          motivo,
        },
      }).then((res) => {
        this.getEstudio();
        // this.$router.push({ name: "Estudios" });
      });
    },
    async toggleFav() {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_API_URL}/favoritos.php`,
        data: {
          idEstudio: this.estudio.idEstudio,
        },
      });
      this.getEstudio();
      this.$root.$emit(
        "snack",
        `Estudio ${this.estudio.idEstudio} ${
          this.estudio.fav ? "eliminado de" : "añadido a"
        } favoritos`
      );
      this.$root.$emit("getFav");
    },
  },
};
</script>

<style></style>
